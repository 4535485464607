<template>
  <div class="my-second xuqiu">
      <Head :title="$t('xuqiu.needs_list')" :show="true" :right="true" >
            <img @click="gotoneedlist"  src="../../../assets/images/needlist.png"  class="right-icon" alt="">
      </Head>
      <div class="common-box xuqiu-box">
          <div class="add-need">
              <van-form class="addform form" ref="addform" @submit="addNeedList">
                  <van-field
                    :class="lang=='cn'?'':'small-width'"
                    readonly
                    clickable
                    name="category"
                    v-model="category.category_name"
                    :label="$t('xuqiu.choose_needs')"
                    right-icon="arrow"
                    @click="showPicker = true"
                    :rules="[{ required: true, message: $t('xuqiu.p_choose_needs') }]"
                  />
                  <van-popup class="pick-popup" :style="{ height: '8rem' }" v-model="showPicker" position="bottom" duration="0.3">
                    <van-picker
                      :title="$t('xuqiu.choose_needs')"
                      show-toolbar
                      :confirm-button-text="$t('info.complate')"
                      value-key="category_name"
                      :columns="columns"
                      @confirm="onConfirm"
                      @cancel="showPicker = false"
                    />
                  </van-popup>

                  <van-field v-model="subject" name="subject" :label="$t('xuqiu.title')"
                      :placeholder="$t('xuqiu.input_title')"
                      :rules="[{ required: true, message: $t('xuqiu.p_input_title') }]"
                  ></van-field>
                  <van-field
                    v-model="message"
                    name="message"
                    rows="4"
                    autosize
                    type="textarea"
                    class="addtext"
                    :placeholder="$t('xuqiu.content')"
                    show-word-limit
                    :rules="[{ required: true, message: $t('xuqiu.input_content') }]"
                  >
                  </van-field>
                  <div class="add-pic">
                      <input  type="file" name="file" id="download" ref="download" style="display:none" @change="uploadFile()" />
                      <div class="photo-item" @click.stop="clicktodo" v-if="!picture"> 
                          <img src="../../../assets/images/add-pic.png" alt="">
                          <p :class="lang=='cn'?'':'small-text'">{{$t('xuqiu.p_input_imgs')}}</p>
                      </div> 
                      <div class="photo-item" v-if="picture"> 
                          <img class="newimg" :src="$host+picture" alt="">
                      </div> 
                  </div>
              </van-form>
          </div>
          <van-button class="add-btn" @click="todoSubmit">{{$t('common.confirm')}}</van-button>
      </div>
  </div>
</template>


<script>
import {mapState,mapMutations} from "vuex"
import {Notify} from "vant"
export default {
  name:"addneed",
  data(){
    return {
      lang:localStorage.getItem("langData") || 'th',
      showPicker:false,
      category:"",
      subject:"",
      message:'',
      picture:'',
    }
  },
  computed:{
    ...mapState(['dashboard']),
    columns(){
      return this.dashboard?this.dashboard.question_category:[]
    }
  },
  mounted(){
    if(!this.dashboard){
        this.$ajax.dashboard()
        .then(res=>{
            this.setdashboard(res.data);
            localStorage.setItem('dashboard',JSON.stringify(res.data))
        })
    }
  },
  methods:{
    clicktodo(){
       this.$refs.download.click();
    },
    uploadFile(){
      // 修改头像 
      this.file = this.$refs.download.files[0];
      let formData = new FormData();
      formData.append('head',this.file);
      this.$ajax.uploaduserhead(formData)
      .then(res=>{
          if(res.code==200){
              this.picture = res.data.file_path;
          }
      })
      
    },
    addNeedList(value){
      this.$ajax.help({
        ...value,
        picture:this.picture,
        category:this.category.category
      })
      .then(res=>{
        if(res.code==200){
            Notify({ type: 'success', message: res.msg });
            this.$router.push({name:'mine-xuqiulist'})
        }
      })
    },
    todoSubmit(){
      // 提交需求
      this.$refs.addform.submit()

    },
    ...mapMutations(['setdashboard']),
    onConfirm(value){
      this.showPicker = false;
      this.category = value;
    },
    onCancel(){

    },
    onChange(){

    },
    showDiglog(){
    },
    gotoneedlist(){
      this.$router.push({
        name:"mine-xuqiulist"
      })
    }
  }
}
</script>